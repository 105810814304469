import {Button, Grid} from "@material-ui/core";
import Slider from "react-slick";
import theme from "../theme";
import {makeStyles} from "@material-ui/styles";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";
import {useEffect, useState} from "react";

const useStyles = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		display: 'flex',
		flexWrap: 'wrap',
		'& .slick-slider': {
			width: '100%',
			padding: '0 40px 0 0',
			overflow: 'hidden',
		},
		'& .slick-track': {
			display: 'flex',
			transform: 'translate3d(0,0,0)',
			willChange: 'transform',
		},
		'& .slick-slide': {
			display: 'flex',
			flexShrink: 0,
			paddingRight: 10,
			'& > div': {
				display: 'flex',
				width: '100%'
			}
		},
		'& .slick-list': {
			marginBottom: 25,
			maxWidth: '100vw',
			transform: 'translate3d(0,0,0)'
		},
		[theme.breakpoints.down('sm')]: {
			'& .slick-slider': {
				paddingRight: 0,
				paddingBottom: 0
			},
			'& .slick-slide': {
				padding: 0,
				paddingRight: 10,
			},
			'&.showAdditional .slick-list': {
				marginRight: '20%',
				marginBottom: 0
			}
		}
	},
	arrow: {
		position: 'absolute',
		bottom: 0,
		zIndex: 10,
		minWidth: 0,
		backgroundColor: theme.palette.primary.background,
		'.blackfriday &': {
			bottom: '-15px',
		},
		boxShadow: '3px 3px 3px rgba(50, 50, 93, 0.04)',
		[theme.breakpoints.down('sm')]: {
			display: 'none !important'
		}
	},
	arrowRight: {
		right: 0,
	},
	arrowLeft: {
		left: 0,
	},
	arrowIcon: {
		display: 'block'
	},
	slickDots: {
		display: 'flex !important',
		justifyContent: 'center',
		padding: 0,
		listStyle: 'none',
		'& li': {
			width: 5,
			height: 5,
			margin: '2px 4px',
			borderRadius: '50%',
			backgroundColor: 'rgba(0,0,0,0.25)',
			'&.slick-active': {
				width: 6,
				height: 6,
				backgroundColor: 'rgba(0,0,0,0.75)'
			},
			'& button': {
				display: 'none'
			}
		},
		[theme.breakpoints.down('sm')]: {
			margin: 0
		}
	}
}), {name: 'SlickWrapper'});

export default function SlickWrapper({children, id, slidesToShow = {sm: 1, md: 2, lg: 4}, rows = 1, center = false, infinite = true, dots = false, showAdditional = true, clientOnly = false}) {
	const classes = useStyles();
	const [isHydrated, setHydrated] = useState(false);

	useEffect(() => {
		setHydrated(true);
	}, []);

	const SlickButtonFix = ({currentSlide, slideCount, children, ...props}) => (
		<span {...props}>{children}</span>
	);

	let settings = {
		dots: dots,
		infinite: infinite,
		slidesToShow: slidesToShow.sm,
		slidesToScroll: slidesToShow.sm,
		rows: rows,
        centerMode: center,
        centerPadding: center ? '100px' : '0px',
		nextArrow: (
			<SlickButtonFix>
				<Button classes={{root: [classes.arrow, classes.arrowRight].join(' ')}}><ChevronRight classes={{root: classes.arrowIcon}}/></Button>
			</SlickButtonFix>
		),
		prevArrow: (
			<SlickButtonFix>
				<Button classes={{root: [classes.arrow, classes.arrowLeft].join(' ')}}><ChevronLeft classes={{root: classes.arrowIcon}}/></Button>
			</SlickButtonFix>
		),
		dotsClass: classes.slickDots,
		responsive: [
			{
				breakpoint: 99999,
				settings: {
					slidesToShow: slidesToShow.lg,
					slidesToScroll: slidesToShow.lg,
					centerMode: children < slidesToShow.lg
				}
			},
			{
				breakpoint: theme.breakpoints.values.md,
				settings: {
					slidesToShow: slidesToShow.md,
					slidesToScroll: slidesToShow.md
				}
			},
			{
				breakpoint: theme.breakpoints.values.sm,
				settings: {
					slidesToShow: slidesToShow.sm,
					slidesToScroll: slidesToShow.sm
				}
			}
		]
	}

	return (
		<Grid
				container
				direction="row"
				justify="flex-start"
				alignItems="stretch"
				spacing={0}
				className={[classes.wrapper, showAdditional ? 'showAdditional' : ''].join(' ')}
			>
			<Slider className={[!isHydrated ? 'hydrating' : ''].join(' ')} {...settings}>
				{children}
			</Slider>
		</Grid>
	);
}
