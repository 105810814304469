import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import theme from "../../../theme";
import { getCMSSettings, getCMSSlot } from "tools/cmsBlockSettings";
import Image from 'next/image';
import Link from 'next/link';
import TextContent from "../text/text-content";
import { useEffect, useState } from "react";

const useStyles = makeStyles({
    container: {
        paddingLeft: 0,
        paddingRight: 0
    },
    wrapper: {
        display: 'flex',
		flexDirection: 'column',
        textDecoration: 'none'
    },
    imageWrapper: {
        position: 'relative',
        background: theme.palette.primary.color,
        display: 'block',
		width: '100%'
    },
    video: {
        display: 'block',
        width: '100%'
    },
    content: {
        margin: '0 auto',
        padding: '15px 12px',
		width: '100%',
		backgroundColor: '#f0f0f0',
        [theme.breakpoints.up('md')]: {
            padding: '20px 20px',
        }
    },
    title: {
        marginTop: 5,
        marginBottom: 0,
        fontFamily: 'Didot, serif',
        textTransform: 'uppercase',
        textAlign: 'right',
        fontSize: 42,
        fontWeight: 400,
        color: '#000000',
        letterSpacing: -2,
        lineHeight: 0.70,
        '.big &': {
            fontSize: 38,
            letterSpacing: -2,
            [theme.breakpoints.up('md')]: {
                marginTop: 20,
                marginRight: 5,
                fontSize: 80,
                letterSpacing: -4
            }
        },
        '& .italic': {
            marginRight: 3,
            display: 'inline-block',
            transform: 'skewX(-20deg)'
        },
        '& .grey': {
            color: '#555555'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 32
        }
    },
    textContent: {
        margin: 0,
        marginTop: 10,
        alignSelf: 'flex-start',
        fontFamily: 'Futura, sans-serif',
        fontWeight: 300,
        letterSpacing: -0.5,
        '& p, & div': {
            fontSize: '12px !important',
            lineHeight: '1.2 !important',
            color: '#000000 !important'
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
            maxWidth: '65%',
            '& p, & div': {
              fontSize: '17px !important'
            },
        }
    }
}, { name: 'SlimAaronsBarker' });

export default function SlimAaronsBarker({ block, slots, category }) {
    const CMSSettings = getCMSSettings(block);
    const classes = useStyles();
    const slot = getCMSSlot('slimAaronsBarker', slots);
    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(() => {
		setHasLoaded(true);
	}, []);

    function getMediaType(media) {
		return media && media.metaData !== null ? 'image' : 'video';
	}

    function getMedia() {
        const media = slot.data.media;

        if(getMediaType(media) === 'image') {
			let image = {
				src: media.url
			};

            image.layout = 'responsive';
            image.width = media.metaData.width;
            image.height = media.metaData.height;

			return <Image {...image} className={[classes.image, 'image'].join(' ')} alt={category.translated?.name} priority={true} unoptimized/>;
		} else if(media) {
			let url = media.url;
			let videoProps = {};

			if(!hasLoaded) {
				videoProps.lazysrc = url;
			} else {
				videoProps.src = url;
			}

			return <video {...videoProps} className={[classes.video, 'video'].join(' ')} autoPlay playsInline muted/>;
		}
    }

    function renderContent() {
        return (
            <>
                <div className={[classes.imageWrapper, 'imageWrapper'].join(' ')}>
                    {getMedia()}
                </div>

                <div className={[classes.content, slot.config.big.value ? 'big': ''].join(' ')}>
                    <h2 className={classes.title}>
                        <span className={'italic'}>Slim</span>Aarons<br/>
                        <span className={'grey'}>{slot.config.title.source === 'static' ? slot.config.title.value : category.translated?.name}</span>
                    </h2>
                    {/* INFO: Saved for the possibility of future usage. */}
                    {/*{slot.config.description.value
                        ? <TextContent
                            content={slot.config.description.source === 'static' ? slot.config.description.value : category.translated?.description}
                            className={classes.textContent}/>
                        : ''}*/}
                </div>
            </>
        );
    }

    return (
        <Container maxWidth={'lg'} className={classes.container}>
            <Grid style={{
                ...CMSSettings.styles,
            }} container>
                <Grid xs={12} item>
                    {slot.config.url.value !== '' ?
                        <Link href={slot.config.url.value}>
                            <a className={classes.wrapper}>
                                {renderContent()}
                            </a>
                        </Link> : renderContent()}
                </Grid>
            </Grid>
        </Container>
    );
}
