import { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import theme from 'components/theme';
import { SearchCore } from '.';

const styles = {
	search__desktop: {
		position: 'unset',
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	}
};

const useStyles = makeStyles(() => styles);

function SearchDesktop({ show, center }) {
	const classes = useStyles();
	return (
		<div className={classes.search__desktop}>
			<SearchCore show={show} center={center} />
		</div>
	);
}

export default memo(SearchDesktop);
