import React, {useContext, useEffect} from 'react';
import {makeStyles} from '@material-ui/styles';
import {CircularProgress, Container, Typography} from "@material-ui/core";
import {useTranslation} from "tools/i18n";
import Search from "../../header/search/main";
import MetaTitle from "../../meta/title";
import PartialSlugSuggestion from "../../products/404-alt";
import MetaNoIndex from "../../meta/noindex";
import {TrackingContext} from "../../providers/tracking";

const useStyles = makeStyles(theme => ({
	main: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		paddingTop: 40,
		paddingBottom: 60
	},
	isLoading: {
		opacity: 0.75
	},
	loader: {
		position: 'absolute',
		left: '50%',
		top: '50%',
		zIndex: 1000
	},
	title: {
		fontFamily: 'Chronicle, serif',
		fontWeight: 400,
		textAlign: 'center',
		maxWidth: 500,
		'& span': {
			display: 'block',
			marginTop: 10,
			fontFamily: 'Chronicle, serif',
			fontSize: 20,
			fontWeight: 500
		}
	},
	separator: {
		margin: 10,
	},
	content: {
		marginTop: 20,
		maxWidth: 400,
		fontSize: 15,
		lineHeight: 1.5,
		color: '#000',
		textAlign: 'center'
	},
	backToHome: {
		marginTop: 30,
		paddingBottom: 4,
		color: '#000',
		textDecoration: 'none',
		borderBottom: '1px solid black'
	}
}));

export default function Error({isLoading, statusCode = '404'}) {
	const classes = useStyles();
	const {t} = useTranslation();
	const {trackingInitiated, actions} = useContext(TrackingContext);

	useEffect(() => {
		if(trackingInitiated) {
			actions.pageView(`Error: ${statusCode}`);
		}
	}, [isLoading, trackingInitiated]);

	return (
		<>
			<MetaTitle title={'Error: ' + statusCode}/>
			<MetaNoIndex/>
			<div className={isLoading ? classes.isLoading : ''} style={{flexGrow: 1}}>
				{isLoading ? <CircularProgress className={classes.loader} /> : ''}
				<Container className={classes.main}>

					<PartialSlugSuggestion />

					<h1 className={classes.title}>
						{t('404-title')}<br/>
						<span>Albert Einstein</span>
					</h1>

					<p className={classes.content}>{t('404-content')}</p>

					<a href={'/'} className={classes.backToHome}>BACK TO HOME PAGE</a>
				</Container>
			</div>
		</>
	);
}
