import React, { useState, memo, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import mobileStyle from '../style/mobileStyle';
import { SearchCore } from '.';
import CloseIcon from 'public/images/icons/close.svg';

const useStyles = makeStyles(() => mobileStyle);

function SearchMobile({ show, emitShowMobile }) {
	const router = useRouter();
	const classes = useStyles();
	const [isHydrated, setHydrated] = useState(false);
	const [isActive, setIsActive] = useState(false);

	useEffect(() => {
		setIsActive(show);
	}, [show]);

	useEffect(() => {
		const handleStart = url => setIsActive(false);
		router.events.on('routeChangeStart', handleStart);
		return () => {
			router.events.off('routeChangeStart', handleStart);
		};
	}, [router]);

	function toggleSearch() {
		if (!isActive && !isHydrated) {
			setHydrated(true);
			setIsActive(true);
			emitShowMobile(true);
		} else {
			emitShowMobile(!isActive);
			setIsActive(!isActive);
		}
	}
	function renderMobileSearch() {
		return (
			<>
				<SearchCore show={true} mobile={true} searchFocus={isActive} right={false} />
				<div className={classes.search__footer} onClick={() => toggleSearch()}>
					<CloseIcon className={'close'} width={32} />
				</div>
			</>
		);
	}

	return (
		<>
			<Drawer
				className={classes.search__mobile}
				anchor={'top'}
				open={isActive}
				onClose={toggleSearch}
				ModalProps={{ keepMounted: true }}
				style={{ height: '100vh' }}
			>
				{renderMobileSearch()}
			</Drawer>
		</>
	);
}

export default memo(SearchMobile);
