import {makeStyles} from "@material-ui/styles";
import theme from "../../../theme";
import TextContent from "../text/text-content";
import {getCMSSettings} from "tools/cmsBlockSettings";
import React from "react";

const useStyles = makeStyles({
	content: {
		position: 'relative',
		'&.title': {
			...theme.typography.h1,
			textAlign: 'center'
		},
		'&.stores-text': {
			'& h2': {
				fontSize: 32
			},
			[theme.breakpoints.up('sm')]: {
				textAlign: 'center',
				'& h2': {
					fontSize: 28
				}
			}
		}
	}
}, {name: 'Text'});

export default function Text({slots, block}) {
	const CMSSettings = getCMSSettings(block);
	const classes = useStyles();

	return (
		<div style={{
				...CMSSettings.styles,
			}}
			 className={[classes.content, block.cssClass].join(' ')}
		>
			<TextContent className={block.cssClass} content={slots[0].data.content} block={block}/>
		</div>
	);
}
