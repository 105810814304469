import Head from 'next/head';
import {useRouter} from "next/router";

export default function MetaUrl({url}) {
    if(url) {
        const router = useRouter();

        // {0,10} max repeats to avoid ReDoS attacks
        const basePath = router.basePath?.replace(/\/{0,10}$/g, '');
        const path = `${basePath}/${url?.replace(/^\/{0,10}/g, '')}`;

        return (
            <Head>
                <meta property="og:url" content={path}/>
            </Head>
        );
    }

    return null;
}
