import dynamic from 'next/dynamic';

// CMS Elements
import Section from './section';
const CmsBlock = dynamic(() => import('./cmsBlock'));
const ListBlock = dynamic(() => import('./listBlock'));

function CMSBlocks({ cmsPage, breadcrumbs, category, categoryMenu, menu }) {
	function renderSections(sections) {
		return sections.map((section, key) => {
			let sizeMode = section.sizingMode === 'boxed' ? false : 'full_width';
			let sectionId = section.blocks?.length > 0 ? section.blocks[0].sectionId : key;

			if (cmsPage.type === 'product_list') {
				sizeMode = 'full_width';
			}

			return (
				<Section section={section} sizeMode={sizeMode} key={sectionId}>
					{renderBlocks(section.blocks, section)}
				</Section>
			);
		});
	}

	function renderBlocks(blocks, section) {
		return blocks.map((block, key) => {
			if (cmsPage.type === 'product_list') {
				return (
					<ListBlock
						block={block}
						section={section}
						cmsPage={cmsPage}
						breadcrumbs={breadcrumbs}
						category={category}
						categoryMenu={categoryMenu}
						menu={menu}
						key={block.sectionId + key}
					/>
				);
			} else {
				return (
					<CmsBlock
						block={block}
						section={section}
						cmsPage={cmsPage}
						breadcrumbs={breadcrumbs}
						category={category}
						categoryMenu={categoryMenu}
						key={block.sectionId + key}
					/>
				);
			}
		});
	}

	return renderSections(cmsPage.sections);
}

export default CMSBlocks;
