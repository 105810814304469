import Head from 'next/head';

export default function MetaDescription({description}) {
	const regex = /(<([^>]+)>)/ig;
	let editedDescription = description ? description.replace(regex, '') : '';
	editedDescription = editedDescription.length > 150 ? editedDescription.substring(0, 147) + '...' : editedDescription;

	return (
		<Head>
			<meta name="description" content={editedDescription}/>
			<meta property="og:description" content={editedDescription}/>
		</Head>
	);
}