import React, { memo } from 'react';
import { SearchDesktop, SearchMobile } from './parts';

function Search({ center = false, show = true, emitShow, searchFocus }) {
	function handleShow(event) {
		emitShow(event, show);
	}

	return (
		<>
			<SearchMobile show={show} emitShowMobile={handleShow} searchFocus={searchFocus} />
			<SearchDesktop center={center} show={show} />
		</>
	);
}

export default memo(Search);
