export function getCMSSettings(block, marginOverride = false) {
  return {
    class: block.cssClass,
    styles: {
      marginBottom: marginOverride ? marginOverride : block.marginBottom,
      /*marginLeft: block.marginLeft,
			marginRight: block.marginRight,*/
      marginTop: marginOverride ? marginOverride : block.marginTop,
      backgroundColor: block.backgroundColor,
    },
    visibility: block.visibility
  };
}

export function getButtonSettings(block, config) {
  return {
    class: block.cssClass,
    styles: {
      wrapper: {
        justifyContent: config.buttonAlign.value,
        marginBottom: block.marginBottom,
        marginTop: block.marginTop,
        backgroundColor: block.backgroundColor ? block.backgroundColor : 'transparent',
        backgroundImage:
          block.backgroundMedia && block.backgroundMedia.url
            ? `url(${block.backgroundMedia.url})`
            : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      },
      button: {
        border: config.buttonColor.value === '' ? `solid 2px ${config.textColor.value}` : 'none',
        color: config.textColor.value,
        backgroundColor: config.buttonColor.value ? config.buttonColor.value : 'transparent',
        height: `${config.buttonHeight.value}px`,
        width: `${config.buttonWidth.value}px`,
        textTransform: config.textUppercase.value ? 'uppercase' : '',
      },
    },
  };
}

export function getCMSSlot(slotName, slots) {
  let foundSlot = false;

  slots.forEach(slot => {
    if (slotName === slot.slot) {
      foundSlot = slot;
    }
  });

  return foundSlot;
}

export function getCMSConfig(configName, config) {
  return config[configName] ? config[configName].value : false;
}
