import { makeStyles } from '@material-ui/styles';
import theme from '../../../theme';
import TextContent from '../text/text-content';
import { getCMSSettings } from 'tools/cmsBlockSettings';

const useStyles = makeStyles(
	{
		wrapper: {
			position: 'relative',
			display: 'flex',
			justifyContent: 'center',
			width: '100%',
		},
		content: {
			width: '100%',
			'&.title': {
				...theme.typography.h1,
				textAlign: 'center'
			},
			'&.stores-text': {
				[theme.breakpoints.up('sm')]: {
					textAlign: 'center'
				}
			},
			[theme.breakpoints.down('xs')]: {
				'&.hasFullMargin': {
					marginBottom: '40px !important'
				}
			}
		}
	},
	{ name: 'Text' }
);

export default function Text({ slots, block, isFullWidth }) {
	const CMSSettings = getCMSSettings(block);
	const classes = useStyles();

	return (
		<div className={classes.wrapper}>
			<div
				style={{
					...CMSSettings.styles,
					marginLeft: block.marginLeft,
					marginRight: block.marginRight
				}}
				className={[classes.content, block.cssClass, block.marginBottom && parseInt(block.marginBottom) >= 60 ? 'hasFullMargin' : ''].join(' ')}
			>
				<TextContent
					content={slots[0].data.content}
					className={block.cssClass}
					isVariant={true}
					style={{ maxWidth: isFullWidth || block.cssClass === 'wider' ? 'none' : 720 }}
				/>
			</div>
		</div>
	);
}
