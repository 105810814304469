import { elevateAutocomplete } from "tools/elevateClient";

async function elevateSearch(query, config, isMobile, state, setState) {
	const searchResults = await elevateAutocomplete(
        query,
        config.elevateMarket,
        config.language.languageCode,
        isMobile ? 'mobile' : 'desktop'
    );

    let products = [];
    searchResults.productSuggestions.forEach((productSuggestions) => {
        products.push(productSuggestions.products[0]);
    });

	if (searchResults) {
		setState(state => {
			return {
				...state,
				focus: true,
				products: products.slice(0, 9),
				suggestions: searchResults.phraseSuggestions,
				hitCount: searchResults.totalHits
			};
		});
	}
}

module.exports = elevateSearch;
