import Select, { components } from 'react-select';
import { useTranslation } from 'tools/i18n';
import theme from 'components/theme';

export default function FilterDropdown({ name, label, currentFilter, options, className, onChange }) {
	const { t } = useTranslation('attributes');

	function formatLabel(data) {
		return <label>{data.label}</label>;
	}

	function Placeholder({ children, ...props }) {
		return <span>{label || t(name)}</span>;
	}

	function Option({ children, ...props }) {
		return (
			<components.Option {...props}>
				<input type="checkbox" value={props.value} checked={props.isSelected} readOnly={true} />
				{children}
			</components.Option>
		);
	}

	function getDefaultOptions() {
		return options.filter(option => {
			if (currentFilter && currentFilter.indexOf(option.value) >= 0) {
				return option;
			}
		});
	}

	return (
		<Select
			className={className}
			isMulti={true}
			hideSelectedOptions={false}
			closeMenuOnSelect={false}
			options={options}
			value={getDefaultOptions()}
			formatOptionLabel={formatLabel}
			isSearchable={false}
			onChange={data => {
				const values = data.map((option, i) => {
					if (!values?.find(v => v === option.value)) {
						return option.value;
					}
				});
				onChange(name, values);
			}}
			components={{
				IndicatorSeparator: null,
				Placeholder: Placeholder,
				Option: Option
			}}
			styles={{
				control: (provided, state) => {
					delete provided.borderWidth;
					delete provided.borderStyle;
					delete provided.borderColor;

					return {
						...provided,
						...theme.typography.dropdown,
						width: '100%',
						boxShadow: 'none',
						borderRadius: 0,
						backgroundColor: '#f0f0f0',
						[theme.breakpoints.up('sm')]: {
							minWidth: 150
						}
					};
				},
				menu: (provided, state) => {
					return {
						...provided,
						boxShadow: theme.boxShadow.dropdownActive
					};
				},
				option: (provided, state) => {
					return {
						...provided,
						display: 'flex',
						alignItems: 'flex-start',
						backgroundColor: 'transparent',
						color: theme.palette.text.black,
						'& input': {
							margin: '4px 6px 0 0',
							verticalAlign: 'middle'
						}
					};
				},
				clearIndicator: (provided, state) => {
					return {
						...provided,
						padding: '8px 0px 8px 8px'
					};
				}
			}}
			instanceId={'select-' + name}
		/>
	);
}
