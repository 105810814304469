import Head from 'next/head';
import React from "react";
import {useRouter} from "next/router";

export default function MetaCanonical({canonicalPathInfo}) {
    if (canonicalPathInfo) {
        const router = useRouter();

        // {0,10} max repeats to avoid ReDoS attacks
        const basePath = router.basePath?.replace(/\/{0,10}$/g, '');
        const path = `${basePath}/${canonicalPathInfo?.replace(/^\/{0,10}/g, '')}`;
        return (
            <Head>
                <link rel="canonical" href={path} />
            </Head>
        );
    }

    return null;
}
