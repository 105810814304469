function getFallbackSuggestions(salesChannel) {
	const suggestionString = salesChannel?.customFields ? salesChannel?.customFields?.search_suggestions : false;
	return suggestionString ? suggestionString.split(',').map((suggestion) => {
		return { highlighted: suggestion.trim(' '), q: suggestion.trim(' ') };
	}) : []

}

async function getLiveSuggestions(salesChannel) {
	let productIds = salesChannel?.customFields ? salesChannel?.customFields?.search_products : false;

	if (productIds) {
		let productResponse = await fetch('/api/product/get/by-ids?ids=' + productIds.join(','), {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			},
			credentials: 'include'
		});

		if (productResponse.status === 200) {
			const data = await productResponse.json();
			return data.products;
		}

		return [];
	}
	return [];
}

module.exports = { getLiveSuggestions, getFallbackSuggestions };
