import {Container} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import theme from "../../../theme";
import { useContext } from "react";
import { SessionContext } from "../../../providers/session";

const useStyles = makeStyles({
	content: {
		...theme.cmsContent,
		position: 'relative',
		padding: 0,
		margin: 0,
		zIndex: 100,
		textAlign: 'center',
		'& h1, & h2': {
			margin: '10px 0 0',
			fontSize: 42,
			color: theme.palette.primary.backgroundContrast,
			lineHeight: 1,
			[theme.breakpoints.down('xs')]: {
				fontSize: '32px !important',
				'&.large-mob': {
					fontSize: '36px !important',
				}
			},
		},
		'& p': {
			margin: '5px 0',
			fontSize: '1rem',
			[theme.breakpoints.down('xs')]: {
				fontSize: 14
			},
		},
		'& .text': {
			padding: '0 30px',
			minHeight: 45
		},
		'.wedding-border &, .wedding-double-content &': {
			maxWidth: 400,
			padding: '30px 40px',
			backgroundColor: 'rgba(255,255,255,0.85)',
			'& h1, & h2, & p, & li': {
				color: 'black'
			},
			'& h2': {
				fontSize: 32
			},
			'& li': {
				fontSize: 13
			},
			'& a': {
				textDecoration: 'none',
				borderBottom: '1px solid black'
			}
		},
		[theme.breakpoints.down('sm')]: {
			...theme.cmsContentMobile
		},
	}
}, {name: 'BannerContent'});


export default function BannerContent({content}) {
	const classes = useStyles();
	const { config } = useContext(SessionContext);

	function handleMyNewportLinks(content) {
		if(content && config.myNewport) {
			return content.replaceAll('%7CmyNewport%7C', config.myNewport);
		}

		return content;
	}

	return (
		content ? <Container className={[classes.content].join(' ')}><div dangerouslySetInnerHTML={{__html: handleMyNewportLinks(content)}}/></Container> : ''
	);
}