import { useTranslation } from 'tools/i18n';
import Link from 'next/link';
import { Grid } from '@material-ui/core';

export default function SearchResultBtn({ query, classes }) {
	const { t } = useTranslation();
	return (
		<Grid xs={12} md={6} lg={6} style={{margin: '20px auto 0'}} item>
			<Link href={{
				pathname: t('search-url'),
				query: { q: query }
			}}>
				<a className={classes.product__showMore}>
					{t('additional-results')}
				</a>
			</Link>
		</Grid>
	);
}
